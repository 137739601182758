.el-form {
  section {
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.56;
    color: $primary-purple;

    p {
      line-height: 1.56;
    }

    a {
      text-decoration: underline;
    }
  }

  .row {
    border: $container-border;
    border-radius: $container-radius;
    padding: 40px;

    .title {
      color: $primary-purple;
      font-size: 18px;
      margin-bottom: 12px;
      font-weight: bold;

      i {
        font-style: normal;
      }

      .i_error {
        color: #e91545;
      }

      .i_success {
        color: #4200eb;
      }
    }

    .tip {
      margin-bottom: 15px;
      font-size: 16px;
      color: $primary-purple;
    }

    /deep/ .confirmId_main {
      display: grid;
      grid-template-columns: 80% 20%;
      grid-template-rows: auto;
      grid-template-areas:
        'left_box right_box'
        'bottom_box bottom_box';
      min-height: 130px;

      .left_box {
        grid-area: left_box;

        ul {
          margin-left: 20px;
        }

        li {
          font-size: 16px;
          font-weight: 200;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.56;
          letter-spacing: normal;
          color: $primary-purple;
          list-style: disc;
          padding-left: 10px;
        }
      }

      .right_box {
        grid-area: right_box;

        .el-form-item {
          margin: 0;
        }

        .upload_demo .button_upload {
          right: 0;
          left: unset;
          background-color: unset;
          border: solid 1px $primary-purple;
          color: $primary-purple;
        }
      }

      .id_form {
        grid-area: bottom_box;
        padding: 30px 30px 0;
        margin: 40px 0 0;

        border: $container-border;
        border-radius: $container-radius;

        .title {
          text-align: center;
          font-size: 16px;
          margin-bottom: 25px;
          font-weight: bold;
          color: $primary-purple;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  /deep/.el-dialog .el-dialog__body {
    padding: 0 70px 30px;
    .dialog_body p {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 550px) {
  .el-form {
    section {
      margin-bottom: 17px;
    }

    .row {
      padding: 20px;
      border: 1px solid $primary-purple;
      border-radius: 10px;

      .title {
        margin-bottom: 16px;
      }

      /deep/ .confirmId_main {
        display: inline;

        .left_box {
          margin-bottom: 33px;
        }

        .left_box,
        .right_box {
          display: inline;
          width: 100%;
        }

        form {
          border: 1px solid $primary-purple !important;
          border-radius: 10px !important;
          display: inline-block;
        }
      }
    }

    /deep/.el-dialog {
      top: 25%;
    }

    /deep/.el-dialog .el-dialog__header {
      display: none;
    }
    /deep/.el-dialog .el-dialog__body {
      padding: 23px 70px;
      .dialog_body p {
        font-size: 16px;
      }
    }
  }
}
